@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap');
.buyInfo {
  display: flex;
  flex-direction: row;
  width: 30%;
  margin: auto;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 30px;
  box-shadow: 2px 2px 10px 2px rgba(136, 136, 136, 0.336);
  height: 50px;
}
@media (max-width: 900px) {
  .buyInfo {
    width: 70%;
  }
}

.buyBtn {
  font-family: "Roboto", sans-serif;
  box-shadow: rgba(0, 0, 0, 0.2) 0 15px 30px -5px;
  background-image: linear-gradient(144deg, #a8f0e2, #a8daf0 50%, #dcecf3); 
  width: 60%;
  color: black;
  font-size: 17px;
  font-weight: bold;
  border: 0px white solid;
  transition-duration: 500ms;
}
.buyBtn:hover {
  border: 2px #494848 solid;
}
.price {
  width: 40%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-family: 'Josefin Sans', sans-serif;
}
.backBtn {
  margin-top: 50px;
  text-align: center;
  margin-bottom: 50px;
}
