.paymentfailed {
  margin-top: 50px;
  width: 60%;
}
@media (max-width: 900px) {
  .paymentfailed {
    width: 100%;
  }
}
.otherPaymentOptions{
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
  line-height: 1.5;
}
.otherPaymentOptions a {
  text-decoration: none;
  color: #18709c;
  margin: 5px;
}