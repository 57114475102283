.contact {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
  line-height: 1.5;
}
.contact a {
  text-decoration: none;
  color: #18709c;
  margin: 5px;
}

/* Style inputs with type="text", select elements and textareas */
input[type="text"],
select,
textarea,
input[type="email"],
input[type="tel"],
input[type="number"] {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style the submit button with a specific background color etc */
input[type="submit"] {
  background-color: #04aa6d;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type="submit"]:hover {
  background-color: #45a049;
}

/* Add a background color and some padding around the form */
.container {
  border-radius: 5px;
  background-color: #fff6ee;
  padding: 20px;
  width: 80%;
  margin: auto;
  font-family: "Roboto", sans-serif;
  margin-bottom: 30px;
}

.send {
  font-size: 25px;
  margin-bottom: 25px;
  text-align: center;
}

@media (max-width: 300px) {
  .finalPrice {
    font-size: 18px;
  }
}
