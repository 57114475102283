.parent {
  display: flex;
  flex-flow: row wrap;
  width: 70%;
  margin: auto;
  /* margin-top: 40px; */
  /* margin-bottom: 40px; */
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
}
@media (max-width: 900px) {
  .parent {
    flex-flow: column wrap;
    width: 90%;
    margin-top: 10px;
  }
}
.parent img {
  width: 40%;
  height: 100%;
}
@media (max-width: 900px) {
  .parent img {
    width: 90%;
    height: 100%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.about {
  width: 50%;
  margin: auto;
  font-size: 20px;
}
@media (max-width: 900px) {
  .about {
    width: 90%;
    margin: auto;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.about span {
  font-weight: bold;
}
