@import url("https://fonts.googleapis.com/css2?family=Changa&display=swap");
.gallery {
  display: block;
  height: 10%;
  color: rgba(0, 0, 0, 0.87);
}

.parent {
  display: flex;
  flex-flow: row wrap;
  width: 80%;
  margin: auto;
  justify-content: space-around;
}
.child {
  margin: 20px;
  transition-duration: 200ms;
  box-shadow: 2px 2px 10px 10px rgba(136, 136, 136, 0.158);
}
.child:hover {
  background-color: #dce4e8;
}
.child img {
  margin: 10px;
  width: 260px;
  height: 260px;
  box-shadow: 1px 1px 5px 2px #07030541;
}
.pName {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 20px;
  margin: 5px;
  text-align: center;
  margin-bottom: 10px;
}

.buybtn {
  margin-top: 50px;
  text-align: center;
  margin-bottom: 900px;
}
@media (max-width: 500px) {
  .buybtn {
    margin-bottom: 350px;
  }
}

.collection {
  margin-bottom: 50px;
}
.noitems {
  width: 70%;
  margin: auto;
}
