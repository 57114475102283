@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap');
.title {
  text-align: center;
  font-size: 40px;
  font-family: 'Josefin Sans', sans-serif;
  color: rgb(73, 72, 72);
  margin-top: 40px;
}
.sub {
  text-align: center;
  font-size: 18px;
  font-style: italic;
  font-family: 'Josefin Sans', sans-serif;
  color: rgb(73, 72, 72); 
  margin-top: 10px;
  width: 70%;
  margin: auto;
}
