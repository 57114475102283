@import url("https://fonts.googleapis.com/css2?family=Changa&display=swap");
.form {
  width: 80%;
  margin: auto;
  font-family: "Roboto", sans-serif;
}
.CheckOut {
  text-align: center;
  font-size: 20px;
  width: 70%;
  margin: auto;
  font-weight: bold;
  margin-bottom: 50px;
}
.finalPrice {
  font-family: "Changa", sans-serif;
}
.finalPrice span {
  font-size: 18px;
}
@media (max-width: 300px) {
  .finalPrice {
    font-size: 18px;
  }
}
@media (max-width: 300px) {
  .finalPrice span {
    font-size: 16px;
  }
}
.painting {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  margin-bottom: 200px;
  box-shadow: 2px 2px 10px 10px rgba(136, 136, 136, 0.158);
  border-radius: 10px;
}
.painting label {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 18px;
  margin: 5px;
}
.painting img {
  width: 200px;
  height: 200px;
  border-radius: 5px;
  margin: 10px;
}

.shipcontainer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
