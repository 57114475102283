.backBtn {
  margin-top: 50px;
  text-align: center;
  margin-bottom: 50px;
}
.desc {
  width: 70%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  /* text-align: center; */
}

@media (max-width: 900px) {
  .desc {
    width: 90%;
    line-height: 1.4;
  }
}

.tv {
  position: relative;
  background: white;
  border-radius: 0% 0% 0% 0% / 0% 0% 0% 0%;
  box-shadow: 15px 15px rgba(0, 0, 0, 0.15);
  transition: all 0.4s ease;
}
.tv:hover {
  border-radius: 0% 0% 50% 50% / 0% 0% 5% 5%;
  box-shadow: 10px 10px rgba(0, 0, 0, 0.25);
}
