@import url("https://fonts.googleapis.com/css2?family=Akaya+Telivigala&family=Allura&family=Cinzel&family=Cookie&family=Redressed&family=Satisfy&display=swap");
.logo {
  color: black; 
  font-family: "Cookie", cursive !important;
  font-size: 40px !important;
}

@media (max-width: 450px) {
  .logo {
    font-size: 35px !important;
  }
}
@media (max-width: 390px) {
  .logo {
    font-size: 30px !important;
  }
}

@media (max-width: 330px) {
  .logo {
    font-size: 25px !important;
  }
}
@media (max-width: 285px) {
  .logo {
    font-size: 18px !important;
  }
}
