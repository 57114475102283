.parent {
  display: flex;
  flex-flow: row wrap;
  width: 80%;
  justify-content: space-around;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 40px;
}

.child {
  margin: 20px;
}
.seeMoreBtn {
  width: 30%;
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
}
