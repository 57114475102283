@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");
.gallery {
  display: block;
  height: 10%;
  color: rgba(0, 0, 0, 0.87);
}

.parent {
  display: flex;
  flex-flow: row wrap;
  width: 80%;
  margin: auto;
  justify-content: space-around;
}
.child {
  margin: 20px;
  transition-duration: 200ms;
  box-shadow: 2px 2px 10px 10px rgba(136, 136, 136, 0.158);
  display: flex;
  justify-content: center;
  align-items: center;
}
.child:hover {
  transform: scale(105%);
}
.child img {
  width: 260px;
  height: 260px;
  box-shadow: 1px 1px 5px 2px #07030541;
}
.img1 {
  background-image: url("../../Assets/category/floral.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.img2 {
  background-image: url("../../Assets/category/landscape.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.img3 {
  background-image: url("../../Assets/category/abstract.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.img4 {
  background-image: url("../../Assets/category/paper.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.img5 {
  background-image: url("../../Assets/category/figure.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.child div {
  font-size: 32px;
  color: white;
  text-shadow: black 4px 4px 3px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: bold;
}
