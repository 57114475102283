@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");
.gallery {
  display: block;
  height: 10%;
  color: rgba(0, 0, 0, 0.87);
}

.parent {
  display: flex;
  flex-flow: row wrap;
  width: 80%;
  margin: auto;
  justify-content: space-around;
}
.child {
  margin: 20px;
  transition-duration: 200ms;
  box-shadow: 2px 2px 10px 10px rgba(136, 136, 136, 0.158);
}
.child:hover {
  background-color: #dce4e8;
}
.child img {
  margin: 10px;
  width: 260px;
  height: 260px;
  box-shadow: 1px 1px 5px 2px #07030541;
}
.pName {
  font-family: "Josefin Sans", sans-serif;
  font-weight: bold;
  font-size: 22px;
  margin: 5px;
  margin-top: 7px;
  text-align: center;
}
.price {
  font-family: "Josefin Sans", sans-serif;
  font-size: 15px;
  margin-bottom: 12px;
  text-align: center;
  margin-top: 12px;
  color: #494848;
  font-weight: bold;
  letter-spacing: 1px;
}
.seeMoreBtn {
  width: 30%;
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
}
