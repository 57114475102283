@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap");
.footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  background-color: #dcecf3;
  font-family: "Josefin Sans", sans-serif;
  color: rgb(73, 72, 72);
}

@media (max-width: 900px) {
  .footer {
    flex-flow: column nowrap;
    justify-content: center;
  }
}
.section {
  margin: 10px auto;
  margin-top: 10px;
}
@media (max-width: 900px) {
  .section {
    width: 70%;
    margin: auto;
  }
}
.rights {
  background-color: #dcecf3;
  color: #494848;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.developed {
  background-color: #dcecf3;
  color: #494848;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  margin-top: -40px;
}
.developed a {
  text-decoration: none;
}
.developed a:hover {
  text-decoration: underline;
}

.section div {
  margin-top: 10px;
  cursor: pointer;
}
.section div:hover {
  opacity: 0.6;
}
.section img:hover {
  opacity: 0.6;
}
.section img {
  opacity: 0.8;
}
.section img {
  height: 50px;
  width: 50px;
  margin-right: 10px;
  cursor: pointer;
}
.footer h1 {
  font-size: 32px;
  font-weight: normal;
}
